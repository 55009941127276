import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import '../styles/WalletHistoryPage.css';

const WalletHistoryPage = () => {
  return (
    <div>
      <div className="history-tab-navigation">
        <NavLink
          to="sales"
          className={({ isActive }) => `history-tab-item ${isActive ? 'active' : ''}`}
        >
          <i className="fas fa-shopping-cart tab-icon"></i> Sales
        </NavLink>
        <NavLink
          to="listings"
          className={({ isActive }) => `history-tab-item ${isActive ? 'active' : ''}`}
        >
          <i className="fas fa-list-alt tab-icon"></i> Listings
        </NavLink>
        <NavLink
          to="purchases"
          className={({ isActive }) => `history-tab-item ${isActive ? 'active' : ''}`}
        >
          <i className="fas fa-shopping-bag tab-icon"></i> Purchases
        </NavLink>
      </div>
      <Outlet />
    </div>
  );
};

export default WalletHistoryPage;
