import React, { useEffect, useState } from 'react';
import '../styles/WalletModal.css';
import { useNavigate } from 'react-router-dom';
import kleverImage from '../assets/klever.png'; // Adjust the path accordingly

const WalletModal = ({ isOpen, onClose, walletAddress }) => {
  const [balance, setBalance] = useState('...');
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen && walletAddress) {
      const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;

      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log('API response data:', data);
          const fetchedBalance = data.data.account.balance / 1000000;
          setBalance(fetchedBalance.toFixed(2)); // Rounds to 2 decimals
        })
        .catch((error) => {
          console.error('Error fetching balance:', error);
          setBalance('Error'); // Handle error case
        });
    }
  }, [isOpen, walletAddress]);

  const handleAccountClick = () => {
    navigate('/wallet');
    onClose();
  };

  const handleTransactionHistoryClick = () => {
    navigate('/wallet/history/listings');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>My Wallet</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <div className="wallet-balance">
        <img src={kleverImage} alt="Klever" className="klever-logo" />
        <span className="balance-amount">{balance}</span> KLV
        </div>
        <div className="modal-menu">
          <div className="menu-item" onClick={handleAccountClick}>
            <span>Account</span>
            <span>&gt;</span> {/* Use HTML entity for '>' */}
          </div>
          <div className="menu-item" onClick={handleTransactionHistoryClick}>
            <span>Transaction History</span>
            <span>&gt;</span> {/* Use HTML entity for '>' */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletModal;
