import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/HomePage.css';

const HomePage = () => {
  const [royaltiesWallet, setRoyaltiesWallet] = useState(null);
  const [kongPool, setKongPool] = useState(null);
  const [kongToBeBurned, setKongToBeBurned] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchBalances = async () => {
    setIsLoading(true);
    try {
      const [royaltiesResponse, kongPoolResponse, kongBurnResponse] = await Promise.all([
        axios.get('https://api.mainnet.klever.finance/v1.0/address/klv19rllvjlz76eph8vlmldmm5rhdyl0kzuhe5c4hp3s4kesyu4dqths8fzhf4'),
        axios.get('https://api.mainnet.klever.finance/v1.0/address/klv1nmrp37z578qmkx785yadmjw5v0a253z3de5l4sp5ldn964prn28s0jdfmv'),
        axios.get('https://api.mainnet.klever.finance/v1.0/address/klv19eumh6lc27m5sykq3764meqz56kcf85903h8s0pysqgc7xfrpy2sk0yfy4')
      ]);

      setRoyaltiesWallet((royaltiesResponse.data.data.account.balance / 1000000).toFixed(2));
      setKongPool((kongPoolResponse.data.data.account.balance / 1000000).toFixed(2));
      setKongToBeBurned((kongBurnResponse.data.data.account.assets['KONG-LGAJ'].balance / 1000).toFixed(2));
    } catch (error) {
      console.error("Failed to fetch balances:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBalances();
    const interval = setInterval(() => {
      fetchBalances();
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-container">
      <div className="content">
        <h1 className="main-heading">Buy, sell, mint<br />and collect <span className="highlight">NFTs</span> with ease.</h1>
      </div>
      <div className="balance-section">
        <div className="balances">
          <div className="balance-item">
            <h3>Royalties Wallet</h3>
            <p>{isLoading ? 'Loading...' : `${royaltiesWallet} KLV`}</p>
            <p className="description">When Royalties Wallet hits 250,000 KLV, the KLV is paid out to all FOMO APE NFT holders. Higher the fur rarity, bigger the share of the pool.</p>
          </div>
          <div className="balance-item">
            <h3>$KONG Pool</h3>
            <p>{isLoading ? 'Loading...' : `${kongPool} KLV`}</p>
            <p className="description">$KONG Pool is distributed every Sunday to $KONG stakers along with other KDA tokens.</p>
          </div>
          <div className="balance-item">
            <h3>$KONG to be Burned</h3>
            <p>{isLoading ? 'Loading...' : `${kongToBeBurned} KONG`}</p>
            <p className="description">$KONG to be Burned is the amount of KONG collected from swap fees, which is burned every Sunday.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
