import React, { useState, useEffect } from 'react';
import '../styles/SwapKakaFlush.css';
import { web, TransactionType } from '@klever/sdk-web';
import { useWallet } from '../components/WalletContext';

const SwapKakaFlush = () => {
    const [kakaBalance, setKakaBalance] = useState('0');
    const [flushBalance, setFlushBalance] = useState('0');
    const [amount, setAmount] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [transactionStatusMessage, setTransactionStatusMessage] = useState('');
    const [transactionResultMessage, setTransactionResultMessage] = useState('');
    const { walletAddress } = useWallet();
    const [isKakaToFlush, setIsKakaToFlush] = useState(true); // Toggle between KAKA to FLUSH and FLUSH to KAKA

    // Conversion rates
    const kakaToFlushRate = 5000000; // 5,000,000 KAKA = 1 FLUSH
    const flushToKakaRate = 4000000; // 1 FLUSH = 4,000,000 KAKA

    const fetchBalances = async () => {
        if (!walletAddress) return;

        const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
        try {
            const response = await fetch(url);
            const data = await response.json();

            const kakaData = data.data.account.assets['KAKA-3DRY'];
            const flushData = data.data.account.assets['FLUSH-2H6Z'];

            if (kakaData) {
                const kakaBalance = adjustBalance(kakaData.balance, kakaData.precision);
                setKakaBalance(kakaBalance);
            }
            if (flushData) {
                const flushBalance = adjustBalance(flushData.balance, flushData.precision);
                setFlushBalance(flushBalance);
            }
        } catch (error) {
            console.error('Error fetching balances:', error);
        }
    };

    useEffect(() => {
        fetchBalances();

        const interval = setInterval(() => {
            fetchBalances();
        }, 4000); // Update balances every 4 seconds

        return () => clearInterval(interval);
    }, [walletAddress]);

    const adjustBalance = (balance, precision) => {
        const factor = Math.pow(10, precision || 0);
        return (balance / factor).toFixed(precision || 0);
    };

    const handleAmountClick = (amount) => {
        setAmount(amount.toString());
    };

    // Reset the selected amount when switching between KAKA to FLUSH and FLUSH to KAKA
    useEffect(() => {
        setAmount(''); // Clear the amount when the swap direction changes
    }, [isKakaToFlush]);

    const sendTransaction = async () => {
        setIsLoading(true);
        const actualAmountToSend = parseInt(amount, 10);

        const payload = {
            amount: actualAmountToSend,
            receiver: 'klv1j5ugwuq6qak0uqdtxy566aauktdr7mhmnx97kthz4da8qmgn9n8qqumkf4', // Replace with the actual receiver address
            kda: isKakaToFlush ? 'KAKA-3DRY' : 'FLUSH-2H6Z',
        };

        try {
            const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }]);
            const signedTx = await web.signTransaction(unsignedTx);
            const response = await web.broadcastTransactions([signedTx]);

            console.log(response);
            setTransactionResultMessage('Transaction successful.\nPlease wait for balance to update.');
            setTimeout(() => {
                setTransactionResultMessage('');
                fetchBalances(); // Refetch balances after transaction
            }, 7000);
        } catch (error) {
            console.error('Transaction error:', error);
            setTransactionResultMessage('Transaction failed.\nPlease try again.');
            setTimeout(() => {
                setTransactionResultMessage('');
            }, 7000);
        } finally {
            setIsLoading(false);
            setAmount('');
        }
    };

    useEffect(() => {
        let message = '';
        if (!walletAddress) {
            message = 'Connect your wallet first.';
        } else if (transactionResultMessage) {
            return;
        } else if (isLoading) {
            message = 'Processing...';
        } else if (!amount) {
            message = 'Select an amount.';
        } else if (parseFloat(amount) > parseFloat(isKakaToFlush ? kakaBalance : flushBalance)) {
            message = `Insufficient ${isKakaToFlush ? 'KAKA' : 'FLUSH'} balance.`;
        } else {
            message = 'Ready to swap';
        }
        setTransactionStatusMessage(message);
    }, [isLoading, amount, kakaBalance, flushBalance, transactionResultMessage, walletAddress, isKakaToFlush]);

    const formatAmount = (amount) => {
        if (isKakaToFlush) {
            return (amount / kakaToFlushRate).toFixed(0);
        } else {
            return (amount * flushToKakaRate).toFixed(0);
        }
    };

    const swapDirection = isKakaToFlush ? 'KAKA to FLUSH' : 'FLUSH to KAKA';

    return (
        <div className="swap-page-kaka-flush">
            <h1>Swap {swapDirection}</h1>
            <div className="balance-info-kaka">
                <div className="balance-item-kaka">
                    <p>KAKA Balance: <span>{kakaBalance}</span></p>
                </div>
                <div className="balance-item-kaka">
                    <p>FLUSH Balance: <span>{flushBalance}</span></p>
                </div>
            </div>

            {/* Swap toggle buttons */}
            <div className="swap-toggle">
                <button 
                    onClick={() => setIsKakaToFlush(true)} 
                    className={isKakaToFlush ? 'selected-kaka' : ''}
                >
                    KAKA to FLUSH
                </button>
                <button 
                    onClick={() => setIsKakaToFlush(false)} 
                    className={!isKakaToFlush ? 'selected-kaka' : ''}
                >
                    FLUSH to KAKA
                </button>
            </div>

            <div className="amount-buttons-kaka">
                {isKakaToFlush
                    ? [5, 10, 20, 50, 100].map((value) => (
                        <button
                            key={value}
                            onClick={() => handleAmountClick(value * 1000000)}
                            className={`amount-button-kaka ${amount === (value * 1000000).toString() ? 'selected-kaka' : ''}`}
                        >
                            {value}M
                        </button>
                    ))
                    : [1, 5, 10, 50].map((value) => (
                        <button
                            key={value}
                            onClick={() => handleAmountClick(value)}
                            className={`amount-button-kaka ${amount === value.toString() ? 'selected-kaka' : ''}`}
                        >
                            {value} FLUSH
                        </button>
                    ))}
            </div>

            {amount && (
                <div className="selected-amount-kaka">
                    <p>Swapping: {amount} {isKakaToFlush ? 'KAKA' : 'FLUSH'}</p>
                    <p>Will Receive: {formatAmount(amount)} {isKakaToFlush ? 'FLUSH' : 'KAKA'}</p>
                </div>
            )}

            <div className="wallet-actions-kaka">
                <button
                    className={`swap-button-kaka ${isLoading || !amount || !walletAddress || parseFloat(amount) > parseFloat(isKakaToFlush ? kakaBalance : flushBalance) ? 'disabled-kaka' : ''}`}
                    onClick={sendTransaction}
                    disabled={isLoading || !amount || !walletAddress || parseFloat(amount) > parseFloat(isKakaToFlush ? kakaBalance : flushBalance)}
                >
                    {isLoading ? 'Processing...' : 'Swap'}
                </button>
            </div>

            <div className="transaction-status-kaka">
                <p>{transactionStatusMessage}</p>
                {transactionResultMessage && <p>{transactionResultMessage}</p>}
            </div>
        </div>
    );
};

export default SwapKakaFlush;
