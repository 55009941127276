import '../styles/Footer.css';
import React from 'react';
import { useWallet } from '../components/WalletContext';

const Footer = () => {
  const { useKdaFee, toggleKdaFee, kdaOptions, selectedKda, setSelectedKda } = useWallet();

  return (
    <footer>
      <div className="kda-fee-toggle">
        <label className="kda-fee-label">
          <input
            type="checkbox"
            checked={useKdaFee}
            onChange={toggleKdaFee}
          />
          Pay Fees with KDA
        </label>
        {useKdaFee && (
          <select value={selectedKda} onChange={(e) => setSelectedKda(e.target.value)}>
            <option value="">Select KDA</option>
            {kdaOptions.map(kda => (
              <option key={kda} value={kda}>{kda}</option>
            ))}
          </select>
        )}
      </div>
      <p>© 2023-2024 Rare Canvas by FOMO Apes Version 2.0.1</p>
    </footer>
  );
}

export default Footer;
