import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/CollectionsPage.css';
import Spinner from '../components/Spinner';

const CollectionsPage = () => {
  const [collections, setCollections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCollectionData = async () => {
      try {
        let allAssets = [];
        const totalPages = 5; // Define the number of pages to fetch

        // Fetch assets data from all pages
        for (let page = 1; page <= totalPages; page++) {
          const assetsResponse = await axios.get(`https://api.mainnet.klever.finance/v1.0/assets/list?page=${page}&limit=100`);
          const assetsData = assetsResponse.data.data.assets.filter(collection => collection.assetType === "NonFungible");
          allAssets = [...allAssets, ...assetsData];
        }

        // Array of marketplace IDs
        const marketplaceIds = ['d4f2bab340c55fde', '7c353c02770da029', '2936933ee43db509', '417b70c0eb7a33cb', '116056b257d9f6d5', 'aa68ad853a09988c'];

        // Fetch stats for each marketplace
        const statsPromises = marketplaceIds.map(id =>
          axios.get(`https://api.mainnet.klever.finance/v1.0/marketplaces/${id}/stats`)
        );

        const statsResponses = await Promise.all(statsPromises);
        let combinedStatsData = [];

        statsResponses.forEach(response => {
          combinedStatsData = [...combinedStatsData, ...response.data.data.stats];
        });

        let mergedCollections = allAssets.map(asset => {
          let totalVolume = 0, totalItems = 0, totalOwners = 0;
          let floorPrices = [];
          let royalties = null; // Initialize as null to handle cases where royalties are not present

          combinedStatsData.forEach(stat => {
            if (stat.assetId === asset.assetId) {
              totalVolume += stat.volumeTotal || 0;
              totalItems += stat.items || 0;
              totalOwners += stat.owners || 0;
              if (stat.floor && stat.floor > 0) {
                floorPrices.push(stat.floor);
              }
            }
          });

          const combinedFloorPrice = floorPrices.length > 0 ? Math.min(...floorPrices) : 0; // Use the lowest non-zero floor price

          if (asset.royalties && asset.royalties.marketPercentage) {
            royalties = (asset.royalties.marketPercentage / 100).toString(); // Convert to string
            // Remove trailing .00 if it's a whole number
            royalties = royalties.includes('.') ? royalties.replace(/\.00$/, '') : royalties;
        }
        

          const metadataObject = asset.uris ? asset.uris.find(uri => uri.key === 'metadata') : null;
          const metadataUri = metadataObject ? metadataObject.value : null;

          return { ...asset, volumeTotal: totalVolume, items: totalItems, owners: totalOwners, floor: combinedFloorPrice, royalties, circulatingSupply: asset.circulatingSupply || 0, metadataUri };
        });

        // Sort collections by volume in descending order
        mergedCollections = mergedCollections.sort((a, b) => b.volumeTotal - a.volumeTotal);

        setCollections(mergedCollections);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCollectionData();
  }, []);

  const handleImageError = (event) => {
    const src = event.target.src;
    if (
      src.startsWith("https://klever-mint.mypinata.cloud/ipfs/") &&
      src !== "https://klever-mint.mypinata.cloud/ipfs/QmNwWcvPmfNzPa4JQppj8mXcaeM8BA2yA5VAjVWYLzTT7a"
    ) {
      event.target.src = src.replace(
        "https://klever-mint.mypinata.cloud/ipfs/",
        "https://ipfs.io/ipfs/"
      );
    }
  };

  return (
    <div className="collections-container">
      <h1>Collections</h1>
      <div className="table-container">
        {loading ? (
          <Spinner />
        ) : (
          <table className="collections-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Collection</th>
                <th>Total Volume</th>
                <th>Floor</th>
                <th>Royalties</th>
                <th>Circulating Supply</th>
              </tr>
            </thead>
            <tbody>
              {collections.map((collection, index) => (
                <tr key={collection.assetId}>
                  <td>{index + 1}</td>
                  <td className="collection-name-cell">
                    {collection.logo && (
                      <img 
                        src={collection.logo} 
                        alt={`${collection.name} Logo`} 
                        className="collection-logo" 
                        onError={handleImageError}
                      />
                    )}
                    <div className="collection-info">
                      <Link to={{
                        pathname: `/collections/${collection.assetId}`,
                        state: { metadataUri: collection.metadataUri }
                      }} className="collection-name">
                        {collection.name}
                      </Link>
                      <p className="collection-ticker">{collection.assetId}</p>
                    </div>
                  </td>
                  <td>{collection.volumeTotal ? `${collection.volumeTotal.toLocaleString()} KLV` : '0 KLV'}</td>
                  <td>{collection.floor ? `${collection.floor.toLocaleString()} KLV` : '0 KLV'}</td>
                  <td>{collection.royalties !== null ? `${collection.royalties}%` : '0%'}</td>
                  <td>{collection.circulatingSupply.toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CollectionsPage;
