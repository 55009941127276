import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import { web } from '@klever/sdk-web';
import axios from 'axios';
import '../styles/AssetDetailsPage.css';

const AssetDetailsPage = () => {
  const { assetId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { walletAddress } = useWallet();
  const [assets, setAssets] = useState([]);
  const [assetDetails, setAssetDetails] = useState({});
  const [page, setPage] = useState(parseInt(new URLSearchParams(location.search).get('page')) || 1);
  const [totalPages, setTotalPages] = useState(0);
  const [listingPrice, setListingPrice] = useState('');
  const [endDate, setEndDate] = useState('');
  const [transactionStatus, setTransactionStatus] = useState('');

  const [isSellModalOpen, setIsSellModalOpen] = useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [sendToAddress, setSendToAddress] = useState('');

  const [isTopChoice, setIsTopChoice] = useState(false);

  const { useKdaFee, selectedKda } = useWallet();

  const toggleSelectAsset = (asset) => {
    setSelectedAssets((prevSelected) => {
      if (prevSelected.includes(asset)) {
        return prevSelected.filter(a => a !== asset);
      } else {
        return [...prevSelected, asset];
      }
    });
  };

  const toggleSelectAll = () => {
    if (selectedAssets.length === assets.length) {
      setSelectedAssets([]);
    } else {
      setSelectedAssets(assets);
    }
  };

  const openSellModal = () => {
    if (selectedAssets.length === 0) return;
    setIsSellModalOpen(true);
  };

  const closeSellModal = () => {
    setIsSellModalOpen(false);
    setTransactionStatus('');
  };

  const openSendModal = () => {
    if (selectedAssets.length === 0) return;
    setIsSendModalOpen(true);
  };

  const closeSendModal = () => {
    setIsSendModalOpen(false);
    setTransactionStatus('');
    setSendToAddress('');
  };

  const fetchMetadata = async (collectionId, nftNonce) => {
    try {
      let matchingAsset = null;
      const limit = 100;
      const maxPages = 5;

      for (let page = 1; page <= maxPages; page++) {
        const url = `https://api.mainnet.klever.finance/v1.0/assets/list?page=${page}&limit=${limit}`;
        const response = await axios.get(url);
        const assets = response.data.data.assets;
        matchingAsset = assets.find(asset => asset.assetId === collectionId);
        if (matchingAsset) {
          break;
        }
      }

      if (!matchingAsset) {
        console.error('Matching asset not found');
        return;
      }

      const metadataObject = matchingAsset.uris.find(uri => uri.key.toLowerCase() === 'metadata');
      let metadataUri = metadataObject ? metadataObject.value : null;

      if (metadataUri && metadataUri === "QmNaa2KQ6NkjjESpPHEnAow9hivnsAkq2Gd6R26cHG28Er") {
        metadataUri = `https://ipfs.io/ipfs/${metadataUri}/${nftNonce}.json`;
      } else if (metadataUri) {
        if (metadataUri.includes("klever-mint.mypinata.cloud")) {
          metadataUri = metadataUri.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }
        metadataUri = `${metadataUri}/${nftNonce}`;
      }

      if (!metadataUri) {
        console.error('Metadata URI not found for', collectionId);
        return null;
      }

      try {
        const metadataResponse = await axios.get(metadataUri);
        const metadata = metadataResponse.data;

        if (metadata.image && metadata.image.includes("www.lovemonsternft.com")) {
          const imageName = metadata.image.split('/').pop();
          metadata.image = `https://ipfs.io/ipfs/QmWVmUDPBeQzv6fG93JxQxFVee8b6smFD3RQosQXJHiZTJ/${imageName}`;
        } else if (metadata.image && metadata.image.includes("klever-mint.mypinata.cloud")) {
          metadata.image = metadata.image.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }

        return metadata;
      } catch (error) {
        console.error('Primary fetch failed, trying fallback for', nftNonce);
        const fallbackUri = `https://${metadataUri}.ipfs.w3s.link/${nftNonce}`;
        const fallbackResponse = await axios.get(fallbackUri);
        return fallbackResponse.data;
      }
    } catch (error) {
      console.error(`Error fetching metadata for asset ${nftNonce}:`, error);
      return null;
    }
  };

  const renderMetadataAttributes = (metadata) => {
    if (metadata && metadata.attributes) {
      return (
        <div className="metadata-attributes-container">
          {metadata.attributes.map((attribute, index) => (
            <div key={index} className="metadata-attribute">
              <span className="metadata-trait-type">{attribute.trait_type}:</span>
              <span className="metadata-value">{attribute.value}</span>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const fetchAssets = async () => {
    try {
      const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}/collection/${assetId}?limit=20&page=${page}`;
      const response = await axios.get(url);
      const mappedAssets = response.data.data.collection.map(asset => ({
        collectionNonceId: asset.assetId,
        collection: asset.collection,
        nftNonce: asset.nftNonce
      }));
      setAssets(mappedAssets);
      setTotalPages(response.data.pagination.totalPages);
      setPage(response.data.pagination.self);

      mappedAssets.forEach(async (asset) => {
        const metadata = await fetchMetadata(asset.collection, asset.nftNonce);
        if (metadata) {
          setAssetDetails(prevDetails => ({
            ...prevDetails,
            [asset.collectionNonceId]: metadata
          }));
        }
      });
    } catch (error) {
      console.error('Error fetching asset details:', error);
    }
  };

  const listAssetsForSale = useCallback(async () => {
    const listingPayloads = selectedAssets.map(asset => {
      const assetId = `${asset.collection}/${asset.nftNonce}`;
      return {
        marketType: 0,
        marketplaceId: "417b70c0eb7a33cb",
        assetId: assetId,
        currencyId: "KLV",
        price: Number(listingPrice) * 10 ** 6,
        endTime: Math.floor(new Date(endDate).getTime() / 1000)
      };
    });

    let feePayload = null;
    let txOptions = undefined;

    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }

    if (isTopChoice) {
      feePayload = {
        amount: 50 * 10 ** 6,
        receiver: "klv1355u5mgxl5h8gmxzz9tjt3jpp8h6frxykvvs8zya7xrd3ghhc06qxa9kyq",
        kda: selectedKda || "KLV",
      };
    }

    try {
      let feeTxHash = "";

      if (feePayload) {
        console.log("Sending fee transaction:", feePayload);
        const feeTx = await web.buildTransaction(
          [{ payload: feePayload, type: 0 }],
          undefined,
          txOptions
        );
        const signedFeeTx = await web.signTransaction(feeTx);
        const feeResponse = await web.broadcastTransactions([signedFeeTx]);
        console.log('Fee transaction response:', feeResponse);

        if (feeResponse.error) {
          throw new Error('Fee transaction failed');
        }

        feeTxHash = feeResponse.data.txsHashes[0];

        const isFeeConfirmed = await waitForTransactionConfirmation(feeTxHash);
        if (!isFeeConfirmed) {
          throw new Error("Fee transaction not confirmed");
        }
      }

      console.log("Sending listing transactions:", listingPayloads);
      const listingTx = await web.buildTransaction(
        listingPayloads.map(payload => ({ payload, type: 18 })),
        undefined,
        txOptions
      );

      const signedListingTx = await web.signTransaction(listingTx);
      const listingResponse = await web.broadcastTransactions([signedListingTx]);

      console.log('Listing transaction response:', listingResponse);
      setTransactionStatus("Listing transaction completed!");

      // Remove the sold assets from selectedAssets and assets
      setAssets(prevAssets => prevAssets.filter(asset => !selectedAssets.includes(asset)));
      setSelectedAssets([]);
      setTransactionStatus("Listing transaction completed!");

    } catch (error) {
      console.error('Error in transactions:', error);
      setTransactionStatus("Transactions failed!");
    } finally {
      setTimeout(closeSellModal, 2000);
    }
  }, [selectedAssets, listingPrice, endDate, isTopChoice, closeSellModal, useKdaFee, selectedKda]);

  async function waitForTransactionConfirmation(txHash) {
    const maxAttempts = 10;
    const delay = 4000;

    for (let i = 0; i < maxAttempts; i++) {
      const isConfirmed = await checkTransactionStatus(txHash);
      if (isConfirmed) {
        return true;
      }
      await new Promise(resolve => setTimeout(resolve, delay));
    }
    return false;
  }

  async function checkTransactionStatus(txHash) {
    try {
      const response = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/${txHash}`);
      const data = await response.json();

      if (response.ok && data.data && data.data.transaction) {
        return data.data.transaction.status === "success";
      }
      return false;
    } catch (error) {
      console.error('Error fetching transaction status:', error);
      return false;
    }
  }

  const sendAssets = async () => {
    const sendPayloads = selectedAssets.map(asset => {
      const assetId = `${asset.collection}/${asset.nftNonce}`;
      return {
        receiver: sendToAddress,
        kda: assetId,
        amount: 1,
      };
    });

    let txOptions = undefined;
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }

    try {
      const sendTx = await web.buildTransaction(
        sendPayloads.map(payload => ({ payload, type: 0 })),
        undefined,
        txOptions
      );

      const signedTx = await web.signTransaction(sendTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Transaction response:', response);

      setTransactionStatus("Transaction sent successfully");

      // Remove the sent assets from selectedAssets and assets
      setAssets(prevAssets => prevAssets.filter(asset => !selectedAssets.includes(asset)));
      setSelectedAssets([]);

    } catch (error) {
      console.error('Error sending transaction:', error);
      setTransactionStatus("Transaction failed!");
    } finally {
      setTimeout(closeSendModal, 2000);
    }
  };

  const getTomorrowDate = () => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const yyyy = tomorrow.getFullYear();
    let mm = tomorrow.getMonth() + 1;
    let dd = tomorrow.getDate();

    mm = mm < 10 ? `0${mm}` : mm;
    dd = dd < 10 ? `0${dd}` : dd;

    return `${yyyy}-${mm}-${dd}`;
  };

  const handleFixedEndDateChange = (e) => {
    const daysToAdd = Number(e.target.value);
    if (daysToAdd) {
      const today = new Date();
      const endDate = new Date(today);
      endDate.setDate(endDate.getDate() + daysToAdd);

      const yyyy = endDate.getFullYear();
      let mm = endDate.getMonth() + 1;
      let dd = endDate.getDate();
      mm = mm < 10 ? `0${mm}` : mm;
      dd = dd < 10 ? `0${dd}` : dd;

      setEndDate(`${yyyy}-${mm}-${dd}`);
    } else {
      setEndDate('');
    }
  };

  useEffect(() => {
    fetchAssets();
  }, [assetId, walletAddress, page]);

  const handlePaginationClick = (newPage) => {
    navigate(`/wallet/${assetId}?page=${newPage}`);
    setPage(newPage);
  };

  const PaginationButtons = ({ currentPage, totalPages }) => {
    return (
      <div className="pagination-controls">
        <button 
          className="pagination-btn previous-btn"
          onClick={() => handlePaginationClick(Math.max(1, currentPage - 1))}
          disabled={currentPage <= 1}>
          Previous
        </button>
        <span className="page-counter">Page {currentPage} of {totalPages}</span>
        <button 
          className="pagination-btn next-btn"
          onClick={() => handlePaginationClick(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage >= totalPages}>
          Next
        </button>
      </div>
    );
  };

  if (!assets.length) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div><PaginationButtons currentPage={page} totalPages={totalPages} /></div>
      
      <div className="bulk-action-tabs">
        <button 
          onClick={toggleSelectAll} 
          className={`bulk-action-button-tab ${selectedAssets.length === assets.length ? 'active' : ''}`}
        >
          <i className={`fas ${selectedAssets.length === assets.length ? 'fa-times-circle' : 'fa-check-circle'}`}></i>
          {selectedAssets.length === assets.length ? 'Deselect All' : 'Select All'}
        </button>

        {selectedAssets.length > 0 && (
          <>
            <button 
              onClick={openSellModal} 
              className="bulk-action-button-tab active"
            >
              <i className="fas fa-tag"></i> Sell Selected ({selectedAssets.length})
            </button>
            <button 
              onClick={openSendModal} 
              className="bulk-action-button-tab active"
            >
              <i className="fas fa-paper-plane"></i> Send Selected ({selectedAssets.length})
            </button>
          </>
        )}
      </div>

      <div className="asset-grid">
        {assets.map(asset => (
          <div 
            key={asset.collectionNonceId} 
            className={`asset-card ${selectedAssets.includes(asset) ? 'selected' : ''}`} 
            onClick={() => toggleSelectAsset(asset)}
          >
            <p>#{asset.nftNonce}</p>
            {assetDetails[asset.collectionNonceId] && (
              <div>
                <img src={assetDetails[asset.collectionNonceId].image} alt="NFT" className="nft-image-wallet" />
                {renderMetadataAttributes(assetDetails[asset.collectionNonceId])}
              </div>
            )}
          </div>
        ))}
      </div>
      
      <PaginationButtons currentPage={page} totalPages={totalPages} />

      {isSellModalOpen && (
        <div className="confirmation-modal">
          <div className="modal-content-confirm">
            <h2>List Selected Assets for Sale</h2>
            <div className="input-group">
              <div className="input-container">
                <input
                  type="text"
                  placeholder="Enter Price in KLV"
                  value={listingPrice}
                  onChange={(e) => setListingPrice(e.target.value)}
                  className="modal-input price-input-field"
                />
              </div>
              <div className="input-container">
                <label htmlFor="endDate" className="modal-label">Expires on:</label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={getTomorrowDate()}
                  className="modal-input modal-select"
                />
              </div>

              <div className="input-container">
                <label htmlFor="fixedEndDate" className="modal-label">Or choose a duration:</label>
                <select 
                  id="fixedEndDate" 
                  onChange={handleFixedEndDateChange} 
                  className="modal-select"
                >
                  <option value="">Select duration</option>
                  <option value="1">1 Day</option>
                  <option value="7">7 Days</option>
                  <option value="30">30 Days</option>
                </select>
              </div>

              <div className="input-container checkbox-container">
                <input
                  type="checkbox"
                  id="topChoice"
                  checked={isTopChoice}
                  onChange={(e) => setIsTopChoice(e.target.checked)}
                  className="modal-checkbox"
                />
                <label htmlFor="topChoice" className="checkbox-label">
                  Top choice? (Additional 50 KLV will be charged)
                </label>
              </div>
            </div>
            <div className="modal-actions">
              <button onClick={listAssetsForSale} className="confirm-button">List for Sale</button>
              <button onClick={closeSellModal} className="cancel-button">Cancel</button>
            </div>
            {transactionStatus && <p>{transactionStatus}</p>}
          </div>
        </div>
      )}

      {isSendModalOpen && (
        <div className="confirmation-modal">
          <div className="modal-content-confirm">
            <h2>Send Selected Assets</h2>
            <div className="input-group">
              <input
                type="text"
                placeholder="Recipient Address"
                value={sendToAddress}
                onChange={(e) => setSendToAddress(e.target.value)}
                className="modal-input"
              />
            </div>
            <div className="modal-actions">
              <button onClick={sendAssets} className="confirm-button">Send</button>
              <button onClick={closeSendModal} className="cancel-button">Cancel</button>
            </div>
            {transactionStatus && <p>{transactionStatus}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetDetailsPage;
