import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/Header.css';
import { useWallet } from './WalletContext';
import WalletModal from '../components/WalletModal';

const Header = () => {
  const { walletAddress, isConnected, connectWallet } = useWallet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for the hamburger menu
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAssets, setFilteredAssets] = useState([]);

  const shortenAddress = (address) => {
    if (!address) return '';
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  const onIconClick = () => {
    setIsModalOpen(true);
    closeMenu(); // Close the menu when the wallet button is clicked
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const fetchAssetList = async () => {
    try {
      const url = 'https://api.mainnet.klever.finance/v1.0/assets/list?limit=100';
      const responsePage1 = await axios.get(`${url}&page=1`);
      const responsePage2 = await axios.get(`${url}&page=2}`);

      const assetsPage1 = responsePage1.data.data.assets;
      const assetsPage2 = responsePage2.data.data.assets;

      return [...assetsPage1, ...assetsPage2]
        .filter(asset => asset.assetType === "NonFungible")
        .map(({ assetId, name, ticker }) => ({ assetId, name, ticker }));
    } catch (error) {
      console.error('Error fetching asset list:', error);
    }
  };

  const searchContainerRef = useRef(null);

  const handleResultClick = () => {
    setSearchTerm('');
    setFilteredAssets([]);
    closeMenu();
  };

  const handleClickOutside = (event) => {
    if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
      setSearchTerm('');
      setFilteredAssets([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchTerm) {
      fetchAssetList().then(assets => {
        if (assets) {
          const formattedSearchTerm = searchTerm.toUpperCase();
          const isNFTSearch = formattedSearchTerm.includes('/'); // Check if it's an NFT search
          if (isNFTSearch) {
            const [collectionId, nftId] = formattedSearchTerm.split('/');
            const matchingAssets = assets.filter(asset => asset.assetId === collectionId);
            if (matchingAssets.length === 1) {
              setFilteredAssets([{ ...matchingAssets[0], nftId }]);
            } else {
              setFilteredAssets([]);
            }
          } else {
            const filtered = assets.filter(asset =>
              asset.name.toLowerCase().includes(formattedSearchTerm.toLowerCase())
            );
            setFilteredAssets(filtered);
          }
        }
      });
    } else {
      setFilteredAssets([]);
    }
  }, [searchTerm]);

  return (
    <header>
      <div className="header-left">
        <h2>
          <Link to="/" className="brand-link">
            <span className="rare">Rare</span>
            <img src="/logoicon2.png" alt="Logo Icon" className="logo-icon" />
            <span className="canvas">Canvas</span>
          </Link>
        </h2>
      </div>

      <div className="header-center">
        <input
          type="text"
          placeholder="Search Collections or NFTs (e.g., FOMO-1RW6/132)"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && (
          <div className="search-results">
            {filteredAssets.map(asset => (
              asset.nftId ? 
                <Link key={asset.assetId} to={`/collections/${asset.assetId}/${asset.nftId}`} onClick={handleResultClick}>
                  {`${asset.name} - NFT ${asset.nftId}`}
                </Link>
                :
                <Link key={asset.assetId} to={`/collections/${asset.assetId}`} onClick={handleResultClick}>
                  {asset.name}
                </Link>
            ))}
          </div>
        )}
      </div>

      <div className={`header-right ${isMenuOpen ? "open" : ""}`}>
        <Link to="/launchpad" onClick={closeMenu}>
          <i className="fas fa-rocket"></i> Launchpad
        </Link>
        <Link to="/collections" onClick={closeMenu}>
          <i className="fas fa-th"></i> Collections
        </Link>
        <Link to="/swap" className="swap-button" onClick={closeMenu}>
          <i className="fas fa-exchange-alt"></i> Swap
        </Link>
        <Link to="/mint" onClick={closeMenu}>
  <i className="fas fa-image"></i> Mint
</Link>

        {isConnected ? (
          <button className="wallet-address" onClick={onIconClick} style={{ marginLeft: '10px' }}>
            {shortenAddress(walletAddress)}
          </button>
        ) : (
          <button className="wallet-button" onClick={connectWallet} style={{ marginLeft: '10px' }}>
            Connect Wallet
          </button>
        )}
      </div>

      <div className="hamburger" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <WalletModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        walletAddress={walletAddress}
      />
    </header>
  );
};

export default Header;
