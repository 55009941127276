import React, { useState, useEffect } from 'react';
import { useWallet } from '../components/WalletContext';
import { useNavigate } from 'react-router-dom';
import '../styles/WalletPage.css';

const WalletPageTokens = () => {
  const { walletAddress } = useWallet();
  const [assets, setAssets] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (walletAddress) {
      const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
      fetch(url)
        .then(response => response.json())
        .then(data => {
          const assetEntries = Object.entries(data.data.account.assets || {});
          const filteredAssets = assetEntries
            .filter(([, assetData]) => assetData.assetType === 0)
            .map(([, assetData]) => ({
              name: assetData.assetName,
              balance: adjustBalance(assetData.balance, assetData.precision),
            }));
          setAssets(filteredAssets);
        })
        .catch(error => console.error('Error fetching assets:', error));
    }
  }, [walletAddress]);

  const adjustBalance = (balance, precision) => {
    const factor = Math.pow(10, precision);
    return (balance / factor).toFixed(precision);
  };

  const handleDetailsClick = (assetId) => {
    navigate(`/wallet/tokens/${assetId}`);
  };
  

  return (
    <div>
      <div className="assets-list">
        {assets.map((asset, index) => (
          <div key={index} className="asset-item">
            <span className="asset-name">{asset.name}</span>
            <span className="asset-balance">{asset.balance}</span>
            <button 
              className="asset-action-button"
              onClick={() => handleDetailsClick(asset.assetId)}
            >
              Details
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletPageTokens;
