import React, { useEffect, useState, useCallback, useRef } from 'react';
import axios from 'axios';
import { useWallet } from '../components/WalletContext';
import { web } from '@klever/sdk-web';
import '../styles/MintPage.css'; // Import the CSS file
import Spinner from '../components/Spinner'; // Import the Spinner component

const MintPage = () => {
    const { isConnected, walletAddress, connectWallet } = useWallet();
    const [displayItos, setDisplayItos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedIto, setSelectedIto] = useState(null);
    const [selectedPackIndex, setSelectedPackIndex] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [transactionStatus, setTransactionStatus] = useState('');

    const mintWindowRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const itoResponse = await axios.get('https://api.kleverchain.cloud/v1.0/ito/list?page=1&limit=100');
                const itos = itoResponse.data.data.itos;

                const filteredItos = itos.filter(ito => 
                    ito.isActive && 
                    ito.mintedAmount < ito.maxAmount
                );

                if (filteredItos.length === 0) {
                    setDisplayItos([]);
                    setLoading(false);
                    return;
                }

                const assetIds = filteredItos.map(ito => ito.assetId);
                const keys = filteredItos.flatMap(ito => ito.packData.map(pack => pack.key));

                const allAssets = await fetchAssetsByIdsAndKeys(assetIds, keys);

                const matchedItos = filteredItos.map(ito => {
                    const asset = allAssets.find(asset => asset.assetId === ito.assetId && asset.assetType === "NonFungible");
                    const keyAsset = allAssets.find(asset => asset.assetId === ito.packData[0].key);
                    
                    const precision = keyAsset ? keyAsset.precision : 0;

                    if (asset) {
                        const updatedLogoUrl = replaceLogoUri(asset.assetId, asset.logo);
                        return { 
                            ...ito, 
                            logoUrl: updatedLogoUrl, 
                            name: asset.name, 
                            precision, 
                            pricePerPack: ito.packData[0].packs[0].price,
                            key: ito.packData[0].key, // Store the key here
                        };
                    }
                    return null;
                }).filter(ito => ito !== null);

                setDisplayItos(matchedItos);
                setLoading(false);

            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        const fetchAssetsByIdsAndKeys = async (assetIds, keys) => {
            let page = 1;
            let fetchedAssets = [];
            let response;

            do {
                response = await axios.get(`https://api.kleverchain.cloud/v1.0/assets/list?page=${page}&limit=100`);
                const assets = response.data.data.assets;

                const matchingAssets = assets.filter(asset => assetIds.includes(asset.assetId) || keys.includes(asset.assetId));
                fetchedAssets = fetchedAssets.concat(matchingAssets);
                page += 1;
            } while (response.data.data.assets.length === 100);

            return fetchedAssets;
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchBalance = async () => {
            if (isConnected && selectedIto) {
                try {
                    const response = await axios.get(`https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`);
                    const assets = response.data.data.account.assets || {};
                    const assetBalance = assets[selectedIto.key]?.balance || 0; // Use selectedIto.key for the balance check
                    setUserBalance(assetBalance / Math.pow(10, selectedIto.precision)); // Convert to correct precision
                } catch (error) {
                    console.error('Error fetching balance:', error);
                    setUserBalance(0);
                }
            }
        };

        fetchBalance();
    }, [isConnected, selectedIto, walletAddress]);

    const replaceLogoUri = (assetId, logoUrl) => {
        if (assetId === 'WCNFT-25HY') {
            return null;
        }

        if (!logoUrl) return logoUrl;

        if (logoUrl.includes("https://klever-mint.mypinata.cloud")) {
            return logoUrl.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
        }

        return logoUrl;
    };

    const formatPrice = (price, precision) => {
        if (precision === 0) return price.toString();
        return parseFloat(price.toFixed(precision)).toString();
    };

    const handleLogoError = (e) => {
        e.target.onerror = null;
        e.target.style.display = 'none';
    };

    const handleMintClick = (ito) => {
        setSelectedIto(ito);
        setSelectedPackIndex(0); // Start with the first pack
        const firstPack = ito.packData[0].packs[0];
        setTotalPrice(calculateTotalPrice(firstPack, ito.precision));
        document.querySelector('.ito-content').classList.add('mint-page-blur');

        // Add event listener for clicks outside the mint window
        document.addEventListener('mousedown', handleClickOutside);
    };

    const calculateTotalPrice = (pack, precision) => {
        const totalPrice = (pack.amount * pack.price) / Math.pow(10, precision);
        return formatPrice(totalPrice, precision);
    };

    const closeMintWindow = () => {
        setSelectedIto(null);
        setSelectedPackIndex(0);
        setTotalPrice(0);
        setTransactionStatus('');
        document.querySelector('.ito-content').classList.remove('mint-page-blur');

        // Remove event listener for clicks outside the mint window
        document.removeEventListener('mousedown', handleClickOutside);
    };

    const mintNft = useCallback(async () => {
        setTransactionStatus('Processing transaction...');
    
        const payload = {
            buyType: 0, // Correctly set buyType for ITO purchases
            id: selectedIto.assetId, // Asset ID from the selected ITO
            amount: selectedIto.packData[0].packs[selectedPackIndex].amount, // Amount from the selected pack
            currencyId: selectedIto.packData[0].key, // Currency ID from the selected pack
        };
    
        const contract = {
            type: 17,
            payload: payload
        };
    
        let txOptions = undefined;
    
        try {
            const unsignedTx = await web.buildTransaction([contract], undefined, txOptions);
            const signedTx = await web.signTransaction(unsignedTx);
            const response = await web.broadcastTransactions([signedTx]);
    
            console.log('Mint NFT transaction response:', response);
    
            setTransactionStatus('Mint transaction completed!');
            setTimeout(closeMintWindow, 2000);
        } catch (error) {
            console.error('Error in mint NFT transaction:', error);
            setTransactionStatus('Mint transaction failed!');
            setTimeout(closeMintWindow, 2000);
        }
    }, [selectedIto, selectedPackIndex, totalPrice]);

    const handleNextPack = () => {
        if (selectedIto && selectedPackIndex < selectedIto.packData[0].packs.length - 1) {
            const nextIndex = selectedPackIndex + 1;
            setSelectedPackIndex(nextIndex);
            setTotalPrice(calculateTotalPrice(selectedIto.packData[0].packs[nextIndex], selectedIto.precision));
        }
    };

    const handlePrevPack = () => {
        if (selectedIto && selectedPackIndex > 0) {
            const prevIndex = selectedPackIndex - 1;
            setSelectedPackIndex(prevIndex);
            setTotalPrice(calculateTotalPrice(selectedIto.packData[0].packs[prevIndex], selectedIto.precision));
        }
    };

    const handleClickOutside = (event) => {
        if (mintWindowRef.current && !mintWindowRef.current.contains(event.target)) {
            closeMintWindow();
        }
    };

    if (loading) {
        return (
            <div className="ito-container">
                <Spinner /> {/* Display the spinner while loading */}
            </div>
        );
    }

    return (
        <div className="ito-container">
            <div className="ito-content">
                <table className="ito-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th></th>
                            <th>Name</th>
                            <th>Collection</th>
                            <th>Minted</th>
                            <th>Total</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayItos.map((ito, index) => {
                            const { logoUrl, name, precision } = ito;

                            const pack = ito.packData[0];
                            const price = precision ? pack.packs[0].price / Math.pow(10, precision) : pack.packs[0].price;

                            return (
                                <tr key={ito.assetId}>
                                    <td>{index + 1}</td>
                                    <td className="ito-logo-cell">
                                        {logoUrl ? (
                                            <img 
                                                src={logoUrl} 
                                                alt="Logo" 
                                                className="ito-logo" 
                                                onError={handleLogoError}
                                            />
                                        ) : (
                                            <div className="ito-logo-placeholder" />
                                        )}
                                    </td>
                                    <td>{name || 'N/A'}</td>
                                    <td>{ito.assetId}</td>
                                    <td>{ito.mintedAmount}</td>
                                    <td>{ito.maxAmount}</td>
                                    <td>
                                        {formatPrice(price, precision)} {pack.key}
                                    </td>
                                    <td>
                                        <button 
                                            className="ito-mint-button" 
                                            onClick={() => handleMintClick(ito)}
                                        >
                                            Mint
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {selectedIto && (
                <div className="mint-window" ref={mintWindowRef}>
                    <div className="mint-window-content">
                        <h2>{selectedIto.name}</h2>
                        <img 
                            src={selectedIto.logoUrl} 
                            alt="Collection Logo" 
                            className="ito-logo" 
                            onError={handleLogoError}
                        />
                        <p>Collection: {selectedIto.assetId}</p>
                        <p>Minted: {selectedIto.mintedAmount} / {selectedIto.maxAmount}</p>
                        
                        <div className="pack-navigation">
                            <button onClick={handlePrevPack} disabled={selectedPackIndex === 0}>
                                &lt;
                            </button>
                            <div className="pack-display">
                                {selectedIto.packData[0].packs[selectedPackIndex].amount}
                            </div>
                            <button onClick={handleNextPack} disabled={selectedPackIndex === selectedIto.packData[0].packs.length - 1}>
                                &gt;
                            </button>
                        </div>

                        <p>Total Price: {totalPrice} {selectedIto.packData[0].key}</p>
                        <p>Your Balance: {userBalance} {selectedIto.packData[0].key}</p>
                        <button 
                            className="ito-mint-button" 
                            onClick={mintNft}
                            disabled={!isConnected || userBalance < totalPrice}
                        >
                            {!isConnected ? 'Connect your wallet!' : userBalance < totalPrice ? 'Not enough balance' : 'Confirm Mint'}
                        </button>
                        <button className="ito-cancel-button" onClick={closeMintWindow}>Cancel</button>
                        {transactionStatus && <p className="transaction-status">{transactionStatus}</p>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default MintPage;
