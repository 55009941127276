import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWallet } from '../components/WalletContext'; // Import useWallet
import { web } from '@klever/sdk-web';
import '../styles/TokenDetailsPage.css'; // Import your CSS file here
import Spinner from '../components/Spinner';

const TokenDetailsPage = () => {
  const { assetId } = useParams();
  const { walletAddress } = useWallet(); // Use the walletAddress from the context
  const { useKdaFee, selectedKda } = useWallet();
  const [tokenDetails, setTokenDetails] = useState(null);
  const [balance, setBalance] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [rewards, setRewards] = useState(0);
  const [transactionStatus, setTransactionStatus] = useState('');
  const [stakedEpoch, setStakedEpoch] = useState(null);
  const [currentEpoch, setCurrentEpoch] = useState(null);
  const [minEpochsToClaim, setMinEpochsToClaim] = useState(0);
  const [minEpochsToUnstake, setMinEpochsToUnstake] = useState(0);
  const [minEpochsToWithdraw, setMinEpochsToWithdraw] = useState(0);
  const [availableEpoch, setAvailableEpoch] = useState("100000000"); // Default value
  const [unfreezingAmount, setUnfreezingAmount] = useState(0);
  




  // Adjust value based on precision
  const adjustValue = (value, precision) => {
    const number = value / Math.pow(10, precision);
    return number.toLocaleString('en-US', {
      minimumFractionDigits: 4,
      maximumFractionDigits: 4
    });
  };

  // Find the latest APR value
  const getLatestAPR = (aprEntries) => {
    if (!aprEntries || aprEntries.length === 0) return null;
    const latest = aprEntries.reduce((prev, current) => (prev.timestamp > current.timestamp) ? prev : current);
    return latest.value / 100; // Convert to percentage
  };

  // Fetch balance and staked amount
  useEffect(() => {
    let intervalId;
    const fetchBalanceAndStaked = async () => {
        try {
          const response = await fetch(`https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`);
          const data = await response.json();
          const assetData = data.data.account.assets[assetId];
  
          if (assetData) {
            setBalance(adjustValue(assetData.balance, assetData.precision));
            setStakedAmount(adjustValue(assetData.frozenBalance, assetData.precision));
            
            // Check if there are buckets and set stakedEpoch from the first bucket
            if (assetData.buckets && assetData.buckets.length > 0) {
              const stakedEpochValue = assetData.buckets[0].stakedEpoch;
              setStakedEpoch(stakedEpochValue); // Set stakedEpoch in state
             
            }
          }
        } catch (error) {
          console.error('Error fetching balance and staked:', error);
        }
      };

      

    const fetchRewards = async () => {
      try {
        const response = await fetch(`https://api.mainnet.klever.finance/v1.0/address/${walletAddress}/allowance?asset=${assetId}`);
        const data = await response.json();
        const rewardsData = data.data.result.allStakingRewards.find(reward => reward.assetId === assetId);
        if (rewardsData) {
          setRewards(adjustValue(rewardsData.rewards, rewardsData.precision));
        }
      } catch (error) {
        console.error('Error fetching rewards:', error);
      }
    };

    if (walletAddress && assetId) {
        fetchBalanceAndStaked();
        fetchRewards();
        // Set interval to call the functions every 4 seconds
        intervalId = setInterval(() => {
          fetchBalanceAndStaked();
          fetchRewards();
        }, 4000);
      }
    
      // Clear interval when component unmounts or dependencies change
      return () => {
        if (intervalId) {
          clearInterval(intervalId);
        }
      };
    }, [walletAddress, assetId]);

    useEffect(() => {
        const fetchCurrentEpoch = async () => {
          try {
            const response = await fetch('https://api.mainnet.klever.finance/v1.0/block/list');
            const data = await response.json();
            const epoch = data.data.blocks[0].epoch;
            setCurrentEpoch(epoch); // Update the currentEpoch state
            
          } catch (error) {
            console.error('Error fetching current epoch:', error);
          }
        };
    
        fetchCurrentEpoch(); // Initial fetch
        const intervalId = setInterval(fetchCurrentEpoch, 10000); // Fetch every 10 seconds
    
        return () => clearInterval(intervalId); // Cleanup interval on component unmount
      }, []);

  // Fetch token details
  useEffect(() => {
    const fetchTokenDetails = async () => {
      try {
        let url = `https://api.mainnet.klever.finance/v1.0/assets/list?page=1&limit=100`;
        let response = await fetch(url);
        let data = await response.json();
        let token = data.data.assets.find(asset => asset.assetId === assetId);
        
        if (!token) {
          // If not found in the first page, check the second page
          url = `https://api.mainnet.klever.finance/v1.0/assets/list?page=2&limit=100`;
          response = await fetch(url);
          data = await response.json();
          token = data.data.assets.find(asset => asset.assetId === assetId);
        }

        if (token) {
          setTokenDetails(token);
        
          // Set the new state variables for min epochs
        setMinEpochsToClaim(token.staking.minEpochsToClaim);
        setMinEpochsToUnstake(token.staking.minEpochsToUnstake);
        setMinEpochsToWithdraw(token.staking.minEpochsToWithdraw);
      } else {
          console.error('Token not found');
        }
      } catch (error) {
        console.error('Error fetching token details:', error);
      }
    };

    fetchTokenDetails();
  }, [assetId]);

  useEffect(() => {
    const fetchAvailableEpoch = async () => {
      try {
        const response = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?type=5&asset=${assetId}&fromAddress=${walletAddress}`);
        const data = await response.json();
        const transactions = data.data.transactions;
  
        if (transactions.length > 0) {
          const unfreezeReceipt = transactions[0].receipts.find(receipt => receipt.typeString === "Unfreeze");
          if (unfreezeReceipt && unfreezeReceipt.availableEpoch) {
            setAvailableEpoch(unfreezeReceipt.availableEpoch);
            
          } else {
            console.log('Unfreeze receipt not found in transactions.');
          }
        } 
      } catch (error) {
        console.error('Error fetching available epoch:', error);
      }
    };
  
    const intervalId = setInterval(fetchAvailableEpoch, 10000);
  
    fetchAvailableEpoch();
  
    return () => clearInterval(intervalId);
  }, [assetId, walletAddress]);

  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        // Fetch unfreeze transactions
        const unfreezeResponse = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?type=5&asset=${assetId}&fromAddress=${walletAddress}`);
        const unfreezeData = await unfreezeResponse.json();
        const unfreezeTransactions = unfreezeData.data.transactions;
        const latestUnfreezeTransaction = unfreezeTransactions.find(tx => tx.status === "success");
  
        // Fetch withdraw transactions
        const withdrawResponse = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?type=8&asset=${assetId}&fromAddress=${walletAddress}`);
        const withdrawData = await withdrawResponse.json();
        const withdrawTransactions = withdrawData.data.transactions;
        const latestWithdrawTransaction = withdrawTransactions.find(tx => tx.status === "success");
  
        // Compare timestamps
        const unfreezeTimestamp = latestUnfreezeTransaction ? latestUnfreezeTransaction.timestamp : 0;
        const withdrawTimestamp = latestWithdrawTransaction ? latestWithdrawTransaction.timestamp : 0;
  
        if (unfreezeTimestamp > withdrawTimestamp) {
          // Set unfreezing amount based on the latest successful unfreeze transaction
          const unfreezeReceipt = latestUnfreezeTransaction.receipts.find(receipt => receipt.typeString === "Unfreeze");
          if (unfreezeReceipt && tokenDetails) {
            const unfreezeValue = unfreezeReceipt.value / Math.pow(10, tokenDetails.precision);
            setUnfreezingAmount(unfreezeValue);
          }
        } else {
          setUnfreezingAmount(0);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
  
    const intervalId = setInterval(fetchTransactionData, 10000);
    fetchTransactionData();
    return () => clearInterval(intervalId);
  }, [assetId, walletAddress, tokenDetails]);
  

  const [isSendModalOpen, setIsSendModalOpen] = useState(false);
  const [sendAmount, setSendAmount] = useState('');
  const [recipientAddress, setRecipientAddress] = useState('');

  const openSendModal = () => {
    setIsSendModalOpen(true);
  };

  const handleSendClick = () => {
    setIsSendModalOpen(true);
  };

  const handleSendConfirm = async () => {
    // Basic validation
    if (!sendAmount || parseFloat(sendAmount) <= 0) {
      alert('Please enter a valid amount to send.');
      return;
    }
    if (!recipientAddress) {
      alert('Please enter a recipient address.');
      return;
    }
  
    // Call sendAsset function
    await sendAsset();
  
    // Reset states after sending
    setSendAmount('');
    setRecipientAddress('');
  };

  const [isStakeModalOpen, setIsStakeModalOpen] = useState(false);
  const [stakeAmount, setStakeAmount] = useState('');

  const openStakeModal = () => {
    setIsStakeModalOpen(true);
  };

  const handleStakeConfirm = () => {
    stakeAsset();
  };

const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false);


const [isClaimModalOpen, setIsClaimModalOpen] = useState(false);

const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);

const openWithdrawModal = () => {
  setIsWithdrawModalOpen(true);
};

const handleWithdrawConfirm = async () => {
    try {
      const payload = {
        kda: assetId,
        withdrawType: 0, // Ensure these payload details are correct
      };
  
      let txOptions = undefined;
      // Include kdaFee in the transaction options if useKdaFee is true
      if (useKdaFee && selectedKda) {
        txOptions = { kdaFee: selectedKda };
      }
  
      const unsignedTx = await web.buildTransaction([
        {
          payload,
          type: 8, // Ensure this is the correct type for withdrawing
        },
      ], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Withdraw transaction response:', response);
  
      setTransactionStatus("Withdraw transaction sent successfully");
      setTimeout(() => {
        setIsWithdrawModalOpen(false); // Close the modal after updating the status
        setTransactionStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error sending withdraw transaction:', error);
      setTransactionStatus("Withdraw transaction failed!");
      setTimeout(() => {
        setIsWithdrawModalOpen(false); // Close the modal after updating the status
        setTransactionStatus('');
      }, 2000);
    }
  };
  
  
  

const openUnstakeModal = () => {
    setIsUnstakeModalOpen(true);
  };

const handleUnstakeConfirm = async () => {
    unstakeAsset();
  };
  
  

const openClaimModal = () => {
  setIsClaimModalOpen(true);
};

const handleClaimConfirm = async () => {
    try {
      const payload = {
        claimType: 0,
        id: assetId,
      };
  
      let txOptions = undefined;
      // Include kdaFee in the transaction options if useKdaFee is true
      if (useKdaFee && selectedKda) {
        txOptions = { kdaFee: selectedKda };
      }
  
      const unsignedTx = await web.buildTransaction([
        {
          payload,
          type: 9, // Ensure this is the correct type for claiming
        },
      ], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Claim transaction response:', response);
  
      setTransactionStatus("Claim transaction sent successfully");
      setTimeout(() => {
        setIsClaimModalOpen(false); // Close the modal after updating the status
        setTransactionStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error sending claim transaction:', error);
      setTransactionStatus("Claim transaction failed!");
      setTimeout(() => {
        setIsClaimModalOpen(false); // Close the modal after updating the status
        setTransactionStatus('');
      }, 2000);
    }
  };
  
  
  
  const sendAsset = async () => {
    const payload = {
      receiver: recipientAddress, // Address to which tokens are being sent
      kda: assetId,   // Ticker symbol of the token
      amount: parseFloat(sendAmount) * Math.pow(10, tokenDetails.precision), // Convert the amount to the smallest unit
    };
  
    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }
  
    try {
      // Adjust the transaction type if needed
      const unsignedTx = await web.buildTransaction([
        {
          payload,
          type: 0, // Example, replace with actual transaction type
        },
      ], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Transaction response:', response);
  
      setTransactionStatus("Transaction sent successfully");
      setTimeout(() => { setIsSendModalOpen(false); setTransactionStatus(''); }, 2000);
    } catch (error) {
      console.error('Error sending transaction:', error);
      setTransactionStatus("Transaction failed!");
      setTimeout(() => { setIsSendModalOpen(false); setTransactionStatus(''); }, 2000);
    }
  };
  
  
  const stakeAsset = async () => {
    if (!stakeAmount || parseFloat(stakeAmount) <= 0) {
      alert('Please enter a valid amount to stake.');
      return;
    }
  
    const payload = {
      kda: assetId,
      amount: parseFloat(stakeAmount) * Math.pow(10, tokenDetails.precision),
    };
  
    let txOptions = undefined;
    // Include kdaFee in the transaction options if useKdaFee is true
    if (useKdaFee && selectedKda) {
      txOptions = { kdaFee: selectedKda };
    }
  
    try {
      const unsignedTx = await web.buildTransaction([
        {
          payload,
          type: 4, // Update with correct type for staking
        },
      ], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const response = await web.broadcastTransactions([signedTx]);
      console.log('Staking transaction response:', response);
  
      setTransactionStatus("Staking transaction sent successfully");
      setTimeout(() => { setIsStakeModalOpen(false); setTransactionStatus(''); setStakeAmount(''); }, 2000);
    } catch (error) {
      console.error('Error sending staking transaction:', error);
      setTransactionStatus("Staking transaction failed!");
      setTimeout(() => { setIsStakeModalOpen(false); setTransactionStatus(''); }, 2000);
    }
  };
  

  const unstakeAsset = async () => {
    // Fetch the bucket ID from the API
    try {
      const response = await fetch(`https://api.mainnet.klever.finance/v1.0/transaction/list?type=4&asset=${assetId}&fromAddress=${walletAddress}`);
      const data = await response.json();
      const transactions = data.data.transactions;
  
      if (transactions.length === 0) {
        console.error('No staking transactions found');
        setTransactionStatus("No staking transactions found!");
        return;
      }
  
      // Find the transaction with the 'Freeze' typeString and extract the bucketId
      const bucketId = transactions[0].receipts.find(receipt => receipt.typeString === "Freeze")?.bucketId;
      if (!bucketId) {
        console.error('Bucket ID not found');
        setTransactionStatus("Bucket ID not found!");
        return;
      }
  
      // Prepare the payload for unstaking
      const payload = {
        kda: assetId,
        bucketId: bucketId,
      };
  
      let txOptions = undefined;
      // Include kdaFee in the transaction options if useKdaFee is true
      if (useKdaFee && selectedKda) {
        txOptions = { kdaFee: selectedKda };
      }
  
      // Create and send the unstake transaction
      const unsignedTx = await web.buildTransaction([
        {
          payload,
          type: 5, // Update with the correct transaction type for unstaking
        },
      ], undefined, txOptions); // Add the txOptions
  
      const signedTx = await web.signTransaction(unsignedTx);
      const responseTx = await web.broadcastTransactions([signedTx]);
      console.log('Unstaking transaction response:', responseTx);
  
      setTransactionStatus("Unstaking transaction sent successfully");
      setTimeout(() => {
        setIsUnstakeModalOpen(false); 
        setTransactionStatus('');
      }, 2000);
    } catch (error) {
      console.error('Error in unstaking transaction:', error);
      setTransactionStatus("Unstaking transaction failed!");
      setTimeout(() => {
        setIsUnstakeModalOpen(false);
        setTransactionStatus('');
      }, 2000);
    }
  };
  

  return (
    <div className="token-details-container">
      {tokenDetails ? (
        <>
          <div className="token-card">
            {/* Token details */}
            <div className="token-header">
              <img src={tokenDetails.logo} alt={`${tokenDetails.name} Logo`} />
            </div>
            <div className="token-detail">
              <h1>{tokenDetails.name} ({tokenDetails.ticker})</h1>
              <p>Initial Supply: {adjustValue(tokenDetails.initialSupply, tokenDetails.precision)}</p>
              <p>Circulating Supply: {adjustValue(tokenDetails.circulatingSupply, tokenDetails.precision)}</p>
              <p>Max Supply: {adjustValue(tokenDetails.maxSupply, tokenDetails.precision)}</p>
              <p>Burned Value: {adjustValue(tokenDetails.burnedValue, tokenDetails.precision)}</p>
              <p>Total Staked: {adjustValue(tokenDetails.staking.totalStaked, tokenDetails.precision)}</p>
              <p>Min Epochs to Claim: {tokenDetails.staking.minEpochsToClaim}</p>
              <p>Min Epochs to Unstake: {tokenDetails.staking.minEpochsToUnstake}</p>
              <p>Min Epochs to Withdraw: {tokenDetails.staking.minEpochsToWithdraw}</p>
              {tokenDetails.staking && tokenDetails.staking.interestType === "APRI" && (
                <p>APR: {getLatestAPR(tokenDetails.staking.apr)}%</p>
              )}
            </div>
          </div>

          <div className="user-info">
            <div className="user-detail balance-detail">
              <span className="detail-label">Balance:</span>
              <span className="detail-value">{balance}</span>
              <button onClick={openSendModal}>Send</button>
              <button onClick={openStakeModal}>Freeze</button>
            </div>

        <div className="user-detail staked-detail">
  <span className="detail-label">Frozen:</span>
  <span className="detail-value">{stakedAmount}</span>
  <button onClick={openUnstakeModal} disabled={stakedAmount <= 0 || stakedEpoch + minEpochsToUnstake > currentEpoch}>
    Unfreeze
  </button>
</div>

{/* Unfreezing Amount Section with Withdraw Button */}
<div className="user-detail unfreezing-detail">
  <span className="detail-label">Unfreezing:</span>
  <span className="detail-value">{unfreezingAmount}</span>

  <button 
  onClick={openWithdrawModal} 
  disabled={availableEpoch === "100000000" || parseInt(availableEpoch) > currentEpoch || unfreezingAmount === 0}
>
  Withdraw
</button>

</div>


  <div className="user-detail rewards-detail">
    <span className="detail-label">Rewards:</span>
    <span className="detail-value">{rewards}</span>
    <button onClick={openClaimModal} disabled={rewards <= 0}>Claim</button>
  </div>
</div>
{isSendModalOpen && (
  <div className="action-modal">
    <div className="action-modal-content">
      <h2>Send {assetId}</h2>
      <div className="send-amount-container">
        <input
          type="number"
          placeholder="Amount to send"
          value={sendAmount}
          onChange={(e) => setSendAmount(e.target.value)}
        />
        <span className="available-balance">Available: {balance}</span>
      </div>
      <input
        type="text"
        placeholder="Recipient address"
        value={recipientAddress}
        onChange={(e) => setRecipientAddress(e.target.value)}
      />
      <div className="action-modal-actions">
        <button onClick={handleSendConfirm}>Confirm</button>
        <button onClick={() => setIsSendModalOpen(false)}>Cancel</button>
        </div>
      {transactionStatus && <div className="transaction-status">{transactionStatus}</div>}
    </div>
  </div>
)}


{isStakeModalOpen && (
      <div className="action-modal">
        <div className="action-modal-content">
          <h2>Freeze {assetId}</h2>
          <div className="stake-amount-container">
            <input
              type="number"
              placeholder="Amount to stake"
              value={stakeAmount}
              onChange={(e) => setStakeAmount(e.target.value)}
            />
            <span className="staked-balance">Available: {balance}</span>
          </div>
          <div className="action-modal-actions">
            <button onClick={handleStakeConfirm}>Confirm</button>
            <button onClick={() => setIsStakeModalOpen(false)}>Cancel</button>
          </div>
          {transactionStatus && <div className="transaction-status">{transactionStatus}</div>}
        </div>
      </div>
    )}
{isUnstakeModalOpen && (
  <div className="action-modal">
    <div className="action-modal-content">
      <h2>Unfreeze {assetId}</h2>
      <div className="confirmation-text">
        <p>Confirming this transaction will unfreeze your entire staked balance of {assetId}.</p>
        <p>Frozen Amount: <strong>{stakedAmount}</strong></p>
      </div>
      <div className="action-modal-actions">
        <button onClick={handleUnstakeConfirm}>Confirm</button>
        <button onClick={() => setIsUnstakeModalOpen(false)}>Cancel</button>
      </div>
      {transactionStatus && <div className="transaction-status">{transactionStatus}</div>}
    </div>
  </div>
)}



{isClaimModalOpen && (
  <div className="action-modal">
    <div className="action-modal-content">
      <h2>Claim Rewards</h2>
      <div className="rewards-amount-container">
        <span className="rewards-balance">Rewards: {rewards}</span>
      </div>
      <div className="action-modal-actions">
        <button onClick={handleClaimConfirm}>Confirm</button>
        <button onClick={() => setIsClaimModalOpen(false)}>Cancel</button>
        </div>
      {transactionStatus && <div className="transaction-status">{transactionStatus}</div>}
    </div>
  </div>
)}

{isWithdrawModalOpen && (
  <div className="action-modal">
    <div className="action-modal-content">
      <h2>Withdraw {assetId}</h2>
      <div className="confirmation-text">
        <p>Confirming this transaction will withdraw your available balance of {assetId}.</p>
        {/* Replace the content here with relevant details for withdrawing */}
        <p>Available to Withdraw: <strong>{unfreezingAmount}</strong></p>
      </div>
      <div className="action-modal-actions">
        <button onClick={handleWithdrawConfirm}>Confirm</button>
        <button onClick={() => setIsWithdrawModalOpen(false)}>Cancel</button>
      </div>
      {transactionStatus && <div className="transaction-status">{transactionStatus}</div>}
    </div>
  </div>
)}

        </>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default TokenDetailsPage;