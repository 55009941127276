import React, { useState, useEffect } from 'react';
import '../styles/SwapKIDKLV.css';
import KONGLogo from '../assets/KONG.png';
import klvLogo from '../assets/klever.png';
import { useWallet } from '../components/WalletContext';
import { web, TransactionType } from '@klever/sdk-web';
import Spinner from '../components/Spinner';
import { appPublicKey } from '../components/applicationSettings';


const swapWalletAddress = 'klv1jjuxa3ev5ue4deayct8q6a8zfee8au0x2j3aqckkruru5j4tqymslqrffh';

const SwapKONGKLVPage = () => {
    const [balances, setBalances] = useState({ user: { KONG: 0, KLV: 0 }, swapWallet: { KONG: 0, KLV: 0 } });
    const [fromToken, setFromToken] = useState('KONG-LGAJ');
    const [toToken, setToToken] = useState('KLV');
    const [amount, setAmount] = useState('');
    const [feeBreakdown, setFeeBreakdown] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [transactionStatusMessage, setTransactionStatusMessage] = useState('');
    const { walletAddress } = useWallet();
    const [receiveAmount, setReceiveAmount] = useState(0);
    const [exchangeRate, setExchangeRate] = useState(null); 
    const [dynamicRate, setDynamicRate] = useState(1);
  
  function pemToBuffer(pem) {
    const pemHeader = "-----BEGIN PUBLIC KEY-----";
    const pemFooter = "-----END PUBLIC KEY-----";
    let pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length);
    pemContents = pemContents.replace(/\s+/g, '');
    const binaryDerString = atob(pemContents);
    return Uint8Array.from(binaryDerString, char => char.charCodeAt(0));
  }
  
  
    const fetchBalances = async () => {
      const fetchBalance = async (address) => {
        const url = `https://api.mainnet.klever.finance/v1.0/address/${address}`;
        const response = await fetch(url);
        const data = await response.json();
        return {
          KONG: data.data.account.assets['KONG-LGAJ'] ? data.data.account.assets['KONG-LGAJ'].balance / Math.pow(10, 3) : 0,
          KLV: data.data.account.assets['KLV'] ? data.data.account.assets['KLV'].balance / Math.pow(10, 6) : 0,
        };
      };
      try {
        
        const swapWalletBalances = await fetchBalance(swapWalletAddress);
        let userBalances = { KONG: 0, KLV: 0 };
        
        if (walletAddress) {
          userBalances = await fetchBalance(walletAddress);
        }
        setBalances({ user: userBalances, swapWallet: swapWalletBalances });
      } catch (error) {
        console.error("Failed to fetch balances", error);
      }
    };
  
    useEffect(() => {
      
      fetchBalances();
      const intervalId = setInterval(fetchBalances, 1000); 
  
      
      return () => clearInterval(intervalId);
    }, [walletAddress]);
  
    useEffect(() => {
      if (balances.swapWallet.KONG > 0 && balances.swapWallet.KLV > 0) {
        const rate = balances.swapWallet.KONG / balances.swapWallet.KLV;
        setExchangeRate(rate); 
        setDynamicRate(rate); 
      }
    }, [balances.swapWallet.KONG, balances.swapWallet.KLV]);
    
  
    const calculateDynamicRateBasedOnLiquidity = (fromTokenAmount, fromToken) => {
      const kongAvailable = balances.swapWallet.KONG;
      const klvAvailable = balances.swapWallet.KLV;
      let adjustedRate = exchangeRate; 
  
      if (fromToken === 'KLV') {
          
          const totalKlvValueInKong = fromTokenAmount * exchangeRate;
          const consumptionRate = totalKlvValueInKong / kongAvailable;
          adjustedRate /= (1 + consumptionRate); 
      } else if (fromToken === 'KONG-LGAJ') {
          
          const totalKongValueInKlv = fromTokenAmount / exchangeRate;
          const consumptionRate = totalKongValueInKlv / klvAvailable;
          adjustedRate *= (1 + consumptionRate); 
      }
  
      setDynamicRate(adjustedRate);
      return adjustedRate;
  };
  
  
  
  const validateAndCalculateFees = (inputAmount) => {
      setTransactionStatusMessage(''); 
  
      const parsedAmount = parseFloat(inputAmount);
      if (isNaN(parsedAmount) || parsedAmount <= 0) {
          setReceiveAmount(0);
          setTransactionStatusMessage('Please enter a valid amount.');
          return;
      }
  
      
      const minAmount = fromToken === 'KLV' ? 100 : 10000; 
      const maxAmount = fromToken === 'KLV' ? 250000 : 5000000; 
      if (parsedAmount < minAmount || parsedAmount > maxAmount) {
          setReceiveAmount(0);
          setTransactionStatusMessage(`Amount must be between ${minAmount} and ${maxAmount} ${fromToken}.`);
          return;
      }
  
      
      const userBalance = fromToken === 'KLV' ? balances.user.KLV : balances.user.KONG;
      if (parsedAmount > userBalance) {
          setReceiveAmount(0);
          setTransactionStatusMessage("Insufficient balance!");
          return;
      }
  
      
      const fixedFee = 20; 
      let rate = calculateDynamicRateBasedOnLiquidity(parsedAmount, fromToken);
      const swapFee = parsedAmount * 0.10; 
      let netAmount = parsedAmount - swapFee;
  
      let calculatedReceiveAmount;
      if (fromToken === 'KLV') {
          calculatedReceiveAmount = (netAmount - fixedFee) * rate; 
          calculatedReceiveAmount = Math.floor(calculatedReceiveAmount * 1000) / 1000;
      } else {
          calculatedReceiveAmount = (netAmount / rate) - fixedFee;
      }
  
  calculatedReceiveAmount = Math.max(0, calculatedReceiveAmount); 
  
  
      if ((fromToken === 'KLV' && calculatedReceiveAmount > balances.swapWallet.KONG) ||
          (fromToken === 'KONG-LGAJ' && calculatedReceiveAmount > balances.swapWallet.KLV)) {
          setTransactionStatusMessage('Swap amount exceeds swap wallet liquidity. Please reduce the swap amount.');
          setReceiveAmount(0);
          return;
      }
  
      
      setReceiveAmount(calculatedReceiveAmount);
      setTransactionStatusMessage("");
      setFeeBreakdown(`
          <div>Swap Fee: ${swapFee.toFixed(2)} ${fromToken}</div>
          <div>Fixed Fee: ${fixedFee} KLV</div>
          <div>Rate: ${fromToken === 'KLV' ? `${rate.toFixed(6)} KONG per KLV` : `${(1 / rate).toFixed(6)} KLV per KONG`}</div>
          <div class="receive-amount">Will receive: <span>${calculatedReceiveAmount.toFixed(3)} ${fromToken === 'KLV' ? 'KONG' : 'KLV'}</span></div>
      `);
  };
  
  
  
  
    const evaluateButtonVisibility = () => {
      
      const userBalance = fromToken === 'KLV' ? balances.user.KLV : balances.user.KONG;
      
      const swapWalletToTokenBalance = toToken === 'KLV' ? balances.swapWallet.KLV : balances.swapWallet.KONG;
    
      const parsedAmount = parseFloat(amount) || 0;
      const isAmountValid =
        (fromToken === 'KLV' && parsedAmount >= 100 && parsedAmount <= 250000) ||
        (fromToken === 'KONG-LGAJ' && parsedAmount >= 10000 && parsedAmount <= 5000000);
      const hasSufficientUserBalance = parsedAmount <= userBalance;
      
      const canSwapWalletCoverTransaction = parseFloat(receiveAmount) <= swapWalletToTokenBalance;
    
      return isAmountValid && hasSufficientUserBalance && canSwapWalletCoverTransaction;
    };
  
    async function encryptDynamicRate(dynamicRate) {
      const publicKeySpki = pemToBuffer(appPublicKey);
  
      const publicKey = await window.crypto.subtle.importKey(
          "spki",
          publicKeySpki,
          {
              name: "RSA-OAEP",
              hash: "SHA-256",
          },
          true,
          ["encrypt"]
      );
  
      const encoder = new TextEncoder();
      const dynamicRateBuffer = encoder.encode(dynamicRate.toString());
  
      const encrypted = await window.crypto.subtle.encrypt(
          { name: "RSA-OAEP" },
          publicKey,
          dynamicRateBuffer
      );
  
      
      const hexString = Array.from(new Uint8Array(encrypted)).map(b => b.toString(16).padStart(2, '0')).join('');
      return hexString;
  }
  
  
  const sendTransaction = async () => {
      setIsLoading(true);
      setTransactionStatusMessage('');
  
      const precision = fromToken === 'KLV' ? 6 : 3; 
      const actualAmount = Number(amount) * Math.pow(10, precision);
  
      const payload = {
          amount: actualAmount,
          receiver: swapWalletAddress,
          kda: fromToken,
      };
  
      try {
          
          const encryptedDynamicRateHex = await encryptDynamicRate(dynamicRate.toFixed(6));
          
          
          const metadataBinary = encryptedDynamicRateHex.match(/.{1,2}/g).map(byte => String.fromCharCode(parseInt(byte, 16))).join('');
          const metadataBase64 = btoa(metadataBinary);
  
          
          const unsignedTx = await web.buildTransaction([{ payload, type: TransactionType.Transfer }], [metadataBase64]);
          const signedTx = await web.signTransaction(unsignedTx);
          await web.broadcastTransactions([signedTx]);
  
          setTransactionStatusMessage('Transaction successful!');
          setTimeout(() => {
              setAmount('');
              setTransactionStatusMessage('');
              setIsLoading(false);
              setFeeBreakdown('');
              fetchBalances();
          }, 8000);
      } catch (error) {
          console.error('Transaction error:', error);
          setTransactionStatusMessage('Transaction failed. Please try again.');
          setIsLoading(false);
      }
  };
  
    
    
  
    const switchTokens = () => {
      setFromToken(toToken);
      setToToken(fromToken);
      fetchBalances();
    };
  
    const handlePercentageClick = (percentage) => {
      const balance = fromToken === 'KLV' ? balances.user.KLV : balances.user.KONG;
      const percentageAmount = balance * (percentage / 100);
      const roundedAmount = Math.floor(percentageAmount * 100) / 100;
      setAmount(roundedAmount.toString());
      validateAndCalculateFees(roundedAmount);
      setTransactionStatusMessage('');
    };
  
    const calculateReceiveAmount = (inputValue) => {
      setAmount(inputValue);
      validateAndCalculateFees(inputValue);
    };
    
    
    return (
      <div className="swap-container">
        <h2 className="text-center">Swap (BETA)</h2>
        
        <div className="swap-wallet-balance-info">
    <h4>Liquidity</h4>
    <div className="token-balances">
      <div className="token-balance">
        <img src={KONGLogo} alt="KONG Token" className="token-logo" />
        <p>{balances.swapWallet.KONG.toFixed(2)}</p>
      </div>
      <div className="token-balance">
        <img src={klvLogo} alt="KLV Token" className="token-logo" />
        <p>{balances.swapWallet.KLV.toFixed(2)}</p>
      </div>
    </div>
  </div>
  
  
  
    
        <div className="form-group">
          <label htmlFor="fromTokenSelect">From</label>
          <div className="token-selection">
            <img src={fromToken === 'KLV' ? klvLogo : KONGLogo} alt="From Token" className="token-logo" />
            <select id="fromTokenSelect" className="form-control" value={fromToken} onChange={switchTokens}>
              <option value="KONG-LGAJ">KONG-LGAJ</option>
              <option value="KLV">KLV</option>
            </select>
            <span className="balance-info">Balance: {fromToken === 'KLV' ? balances.user.KLV.toFixed(4) : balances.user.KONG.toFixed(3)}</span>
          </div>
        </div>
    
        <div className="form-group">
          <label htmlFor="toTokenSelect">To</label>
          <div className="token-selection">
            <img src={toToken === 'KLV' ? klvLogo : KONGLogo} alt="To Token" className="token-logo" />
            <select id="toTokenSelect" className="form-control" value={toToken} onChange={switchTokens}>
              <option value="KLV">KLV</option>
              <option value="KONG-LGAJ">KONG-LGAJ</option>
            </select>
            <span className="balance-info">Balance: {toToken === 'KLV' ? balances.user.KLV.toFixed(4) : balances.user.KONG.toFixed(3)}</span>
          </div>
        </div>
  
        <div className="exchange-rate-display">
    <p>Current Rate: 
      {fromToken === 'KONG-LGAJ' ? 
        `1 KONG = ${(1 / dynamicRate).toFixed(6)} KLV` : 
        `1 KLV = ${dynamicRate.toFixed(6)} KONG`}
    </p>
  </div>
  
  
  
  
  
            
        <div className="form-group">
    <label>Amount</label>
    <input type="number" className="form-control" placeholder="Enter amount" value={amount} onChange={(e) => calculateReceiveAmount(e.target.value)} />
    {/* Percentage Buttons */}
    <div className="percentage-buttons">
      {[10, 25, 50, 100].map((percent) => (
        <button
          key={percent}
          className="btn-percentage"
          onClick={() => handlePercentageClick(percent)}
          style={{ margin: '5px' }}
        >
          {percent}%
        </button>
      ))}
    </div>
  </div>
  
        
        <div className="fee-details" dangerouslySetInnerHTML={{ __html: feeBreakdown }}></div>
        
        {evaluateButtonVisibility() && (
        <button onClick={sendTransaction} className="btn btn-block" disabled={isLoading}>Swap</button>
      )}
      
      {/* Transaction Status Message */}
      {transactionStatusMessage && <div>{transactionStatusMessage}</div>}
  
      {/* Overlay Spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <Spinner /> {/* Or use your spinner code here */}
        </div>
      )}
    </div>
  );
    
    
  };
  
  export default SwapKONGKLVPage;
  