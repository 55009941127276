import ColorThief from 'colorthief';

export const getPrimaryColorFromImage = (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.src = imageUrl;
    img.onload = () => {
      const colorThief = new ColorThief();
      const colors = colorThief.getPalette(img, 3); 
      const hexColors = colors.map(color => `rgb(${color.join(',')})`);
      resolve(hexColors);
    };
    img.onerror = (err) => {
      reject(err);
    };
  });
};
