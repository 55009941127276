import React from 'react';

const WalletSalesPage = () => {
  return (
    <div>
      <p>Coming soon</p>
    </div>
  );
};

export default WalletSalesPage;
