import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWallet } from '../components/WalletContext';
import '../styles/WalletPage.css';

const WalletPage = () => {
  const { walletAddress } = useWallet();
  const [assets, setAssets] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();

  const replaceLogoUri = (assetId, logoUrl) => {
    if (assetId === 'WCNFT-25HY') {
      return null; // Skip loading the image for this specific asset
    }

    if (!logoUrl) return logoUrl;

    if (logoUrl.includes("https://klever-mint.mypinata.cloud")) {
      return logoUrl.replace("https://klever-mint.mypinata.cloud", "https://ipfs.io");
    }

    return logoUrl;
  };

  useEffect(() => {
    const fetchAssetLogo = async (assetId) => {
      try {
        const response = await fetch(`https://api.mainnet.klever.finance/v1.0/assets/${assetId}`);
        const data = await response.json();
        return replaceLogoUri(assetId, data.data.asset.logo); // Pass both assetId and logoUrl to replaceLogoUri
      } catch (error) {
        console.error('Error fetching asset logo:', error);
        return '';
      }
    };

    const fetchAssets = async () => {
      if (walletAddress) {
        const url = `https://api.mainnet.klever.finance/v1.0/address/${walletAddress}`;
        try {
          const response = await fetch(url);
          const data = await response.json();
          const assetType = location.pathname.includes('/tokens') ? 0 : 1;
          const assetEntries = Object.entries(data.data.account.assets || {});
          const filteredAssets = await Promise.all(assetEntries
            .filter(([, assetData]) => assetData.assetType === assetType)
            .map(async ([, assetData]) => {
              const logoUrl = await fetchAssetLogo(assetData.assetId);
              return {
                assetId: assetData.assetId,
                name: assetData.assetName,
                balance: assetType === 0 ? adjustBalance(assetData.balance, assetData.precision) : assetData.balance,
                logoUrl,
              };
            }));
          setAssets(filteredAssets);
        } catch (error) {
          console.error('Error fetching assets:', error);
        }
      }
    };

    fetchAssets();
  }, [walletAddress, location.pathname]);

  const adjustBalance = (balance, precision) => {
    const factor = Math.pow(10, precision);
    return (balance / factor).toFixed(precision);
  };

  const checkActive = (path) => {
    return location.pathname === path;
  };

  const handleViewClick = (assetId) => {
    if (location.pathname.includes('/tokens')) {
      navigate(`/wallet/tokens/${assetId}`);
    } else {
      navigate(`/wallet/${assetId}?page=1`);
    }
  };
  
  return (
    <div>
      <div className="tab-navigation">
        <button
          className={`tab-item ${location.pathname === '/wallet' ? 'active' : ''}`}
          onClick={() => navigate('/wallet')}
        >
          <i className="fas fa-image tab-icon"></i> NFTs
        </button>
        <button
          className={`tab-item ${location.pathname === '/wallet/tokens' ? 'active' : ''}`}
          onClick={() => navigate('/wallet/tokens')}
        >
          <i className="fas fa-coins tab-icon"></i> Tokens
        </button>
      </div>
  
      <div className="assets-list">
        {assets.map((asset, index) => (
          <div key={index} className="asset-item">
            {asset.logoUrl ? (
              <img src={asset.logoUrl} alt={`${asset.name} logo`} className="walletAssetLogo" />
            ) : (
              <div className="walletAssetLogo placeholder-logo" />
            )}
            <span className="asset-name">{asset.name}</span>
            <span className="asset-balance">{asset.balance}</span>
            <button
              className="asset-action-button"
              onClick={() => handleViewClick(asset.assetId)}
            >
              {location.pathname.includes('/tokens') ? 'Details' : 'View'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WalletPage;
